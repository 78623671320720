const _1MB = 1048576;
const _1GB = 1073741824;

const sizeToString = (Size) => {
  if (Size < _1MB) {
    let Out = (Size / 1024).toFixed(2);
    // "%.02f KB";
    return Out + " KB";
  } else if (Size >= _1MB && Size < _1GB) {
    let Out = (Size / _1MB).toFixed(2);
    //"%.02f MB"
    return Out + " MB";
  }

  let Out = (Size / _1GB).toFixed(2);
  //"%.02f GB"
  return Out + " GB";
};

export default sizeToString;
