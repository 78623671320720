import { useState, useEffect } from "react";
import { DateTime } from "luxon";

// material-ui
import { Grid, Box } from "@mui/material";

// project imports
import BackupActivityCard from "ui-component/cards/admin/backup-activity-card";
import PaginatedBackupActivityTable from "ui-component/tables/paginated-backup-activity-table";
import { gridSpacing } from "store/constant";

const BackupActivity = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  // Today
  let todayStartDate = DateTime.now().startOf("day").toJSDate();
  let todayEndDate = DateTime.now().endOf("day").toJSDate();

  // Last 7 days
  let lastSevenDaysStartDate = DateTime.now()
    .minus({ days: 7 })
    .startOf("day")
    .toJSDate();
  let lastSevenDaysEndDate = DateTime.now().endOf("day").toJSDate();

  // Last 14 days
  let lastFourteenDaysStartDate = DateTime.now()
    .minus({ days: 14 })
    .startOf("day")
    .toJSDate();
  let lastFourteenDaysEndDate = DateTime.now().endOf("day").toJSDate();

  // Last 30 days
  let lastThirtyDaysStartDate = DateTime.now()
    .minus({ days: 30 })
    .startOf("day")
    .toJSDate();
  let lastThirtyDaysEndDate = DateTime.now().endOf("day").toJSDate();

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <BackupActivityCard
            cardTitle="Today"
            queryStartDate={todayStartDate}
            queryEndDate={todayEndDate}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <BackupActivityCard
            cardTitle="Last 7 Days"
            queryStartDate={lastSevenDaysStartDate}
            queryEndDate={lastSevenDaysEndDate}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <BackupActivityCard
            cardTitle="Last 14 Days"
            queryStartDate={lastFourteenDaysStartDate}
            queryEndDate={lastFourteenDaysEndDate}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <BackupActivityCard
            cardTitle="Last 30 Days"
            queryStartDate={lastThirtyDaysStartDate}
            queryEndDate={lastThirtyDaysEndDate}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "20px" }}>
        <PaginatedBackupActivityTable />
      </Box>
    </>
  );
};

export default BackupActivity;
