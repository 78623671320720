let config = {};

if (!process.env.NODE_ENV) process.env.NODE_ENV = 'development';

const IS_PROD = process.env.NODE_ENV === 'production';
config.SERVER = IS_PROD
  ? `https://${process.env.REACT_APP_DOMAIN_NAME}`
  : `http://localhost:${process.env.REACT_APP_DEFAULT_PORT}`;
config.PORT = 80;
config.UserTypeOptions = [
  { key: 'Administrator', text: 'Administrator', value: 'administrator' },
  { key: 'Customer', text: 'Customer', value: 'customer' },
];
config.basename = '';
config.defaultPath = '/dashboard/default';
config.fontFamily = 'Roboto';
config.borderRadius = 12;

export default config;
