import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useLocalStorageState from "use-local-storage-state";
import ErrorBoundary from "containers/error-boundary";
import AppRoutes from "./routes";
import { Toaster } from "react-hot-toast";

// project imports
import NavigationScroll from "layout/navigation-scroll";
import { SET_USER } from "store/actions";

const App = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useLocalStorageState("user", {
    ssr: false,
    defaultValue: {},
  });

  useEffect(() => {
    dispatch({ type: SET_USER, user: user });
  }, [dispatch, user]);

  return (
    <ErrorBoundary>
      <NavigationScroll>
        <Toaster position="top-right" reverseOrder={false} />
        <AppRoutes user={user} />
      </NavigationScroll>
    </ErrorBoundary>
  );
};

export default App;
