import * as React from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Config from 'config';
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import IvyBackupLogo from 'assets/images/logos/main-logo.png';

const RequestTrialLicense = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [trialRequested, setTrialRequested] = React.useState(false);

  const RequestTrial = async (email) => {
    let response = {};

    setIsLoading(true);

    try {
      response = await Axios.post(
        `${Config.SERVER}/api/v2/licensing/trial/request`,
        {
            emailAddress: email
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      toast.error(error?.response?.data);
    }

    setIsLoading(false);

    return response.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    let email = data.get('email');

    if (email.length === 0) {
      toast.error('Please provide an email address.');
      return;
    }

    let response = await RequestTrial(email);

    if (response) {
        setTrialRequested(true);
    }
  };

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          alt="IvyBackup Logo"
          src={IvyBackupLogo}
          sx={{ width: 100, height: 100 }}
        />
        <Typography component="h1" variant="h5">
          Request Trial License
        </Typography>
        { isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                    <CircularProgress size="80px" color="inherit"/>
                </Box>
            ) : (
                    !trialRequested ? 
                        <Box>
                            <Typography sx={{ marginTop: "10px" }} component="p" variant="body1">
                                We'll email you a trial license so you can get started as soon as possible.
                            </Typography>
                            
                            <Box component="form" onSubmit={handleSubmit} noValidate 
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, fontWeight: '700' }}
                            >
                                Get Started
                            </Button>
                            </Box>
                        </Box> :
                        <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ marginTop: "10px" }} component="p" variant="body1">
                                We've just sent you a trial license key. Please check your email.
                            </Typography>
                            <a
                                href="https://www.ivybackup.com"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button variant="contained" sx={{ mt: 3, mb: 2, fontWeight: '700' }}>
                                    Back Home
                                </Button>
                            </a>
                        </Box>
            )
        }


        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
                <Typography sx={{ marginTop: "10px", alignItems: "center" }} component="p" variant="body1">
                    Questions? You can reach us at
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: "10px" }}>
                <a href="mailto:support@ivybackup.com">
                    <Typography variant="body1">
                        support@ivybackup.com
                    </Typography>
                </a>
            </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default RequestTrialLicense;