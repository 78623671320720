import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C0C0C0",
    },
    secondary: {
      main: "#db9c65",
    },
    orange: {
      main: "#ffab91",
      light: "#fbe9e7",
      dark: "#d84315",
    },
    error: {
      main: red.A400,
    },
  },
  customization: {
    borderRadius: 20,
  },
  background: "#f0f3f4",
});

export default theme;
