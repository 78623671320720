import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Config from "config";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

// material-ui
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
} from "@mui/material";

// project imports
import SkeletonBackupActivityCard from "ui-component/cards/skeleton/admin/backup-activity-card";

const BackupActivityCard = ({
  isLoading,
  cardTitle,
  queryStartDate,
  queryEndDate,
}) => {
  const [backupsRunCount, setBackupsRunCount] = useState(0);

  const customization = useSelector((state) => state.customization);

  const user = customization.user;

  useEffect(() => {
    const getBackupsRun = async () => {
      let response = {};

      try {
        let params = `pageNo=1&size=10&startDate=${queryStartDate}&endDate=${queryEndDate}`;
        response = await Axios.get(
          `${Config.SERVER}/api/v2/analytics/backups-from-range?${params}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": user.token,
            },
            timeout: 5000,
          }
        );
      } catch (error) {
        if (error?.response?.data) toast.error(error?.response?.data);
      }

      return response.data;
    };

    getBackupsRun().then((backups) => {
      if (backups) {
        setBackupsRunCount(backups.count);
      }
    });
  }, [queryStartDate, queryEndDate, user?.token]);

  return (
    <>
      {isLoading ? (
        <SkeletonBackupActivityCard />
      ) : (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardHeader title={cardTitle} />
              <Link to="/admin/backups/activity/list">
                <CardContent>
                  <Typography variant="h3" color="text.secondary">
                    {backupsRunCount}
                  </Typography>
                </CardContent>
              </Link>
            </Box>
          </CardActionArea>
        </Card>
      )}
    </>
  );
};

BackupActivityCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default BackupActivityCard;
