// assets
import {
  IconKey,
  IconDashboard,
  IconLogout,
  IconAdjustmentsHorizontal,
} from "@tabler/icons";

// constant
const icons = { IconKey, IconDashboard, IconLogout, IconAdjustmentsHorizontal };

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    // {
    //     id: 'default',
    //     title: 'Dashboard',
    //     type: 'item',
    //     url: '/dashboard',
    //     icon: icons.IconDashboard,
    //     breadcrumbs: false
    // },
    {
      id: "user-license",
      title: "My License",
      type: "item",
      url: "/my-license",
      icon: icons.IconKey,
      breadcrumbs: false,
    },
    {
      id: "user-activity",
      title: "My Account",
      type: "item",
      url: "/my-account",
      icon: icons.IconAdjustmentsHorizontal,
      breadcrumbs: false,
    },
    {
      id: "logout",
      title: "Logout",
      type: "item",
      url: "/logout",
      icon: icons.IconLogout,
      breadcrumbs: false,
    },
  ],
};

const menuItems = {
  items: [dashboard],
};

export default menuItems;
