import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import isEmpty from 'services/is-empty.js';

// Layouts
import VisitorLayout from 'layout/visitor-layout';
import AuthenticationLayout from 'layout/authentication-layout';
import AdministratorDashboardLayout from 'layout/administrator-dashboard-layout';
import CustomerDashboardLayout from 'layout/customer-dashboard-layout';

// Visitor Pages
import Landing from 'pages/landing';
import NotFound from 'pages/404';

// Trial licenses
import RequestTrialLicense from 'pages/licensing/request-trial-license';

// Authenticaton Pages
import Register from 'pages/register';
import LoginPage from 'pages/login';
import LogoutRedirect from 'pages/authentication/logout-redirect';
import VerifyEmail from 'pages/authentication/verify-email';

// Customer Dashboard Pages
import RegularMyLicense from 'pages/regular/account/my-license';
import RegularMyAccount from 'pages/regular/account/my-account';

// Administrator Dashboard Pages
import Dashboard from 'pages/admin/dashboard';
import ListAdmins from 'pages/admin/admins/list';
import CreateAdmins from 'pages/admin/admins/create';
import ListUsers from 'pages/admin/users/list';
import CreateUsers from 'pages/admin/users/create';
import EditUsers from 'pages/admin/users/edit';
import BackupActivity from 'pages/admin/backups/activity';
import RestoreActivity from 'pages/admin/restores/activity';
import ViewAnalytics from 'pages/admin/analytics/view';
import ListLicenses from 'pages/admin/licenses/list';
import CreateLicenses from 'pages/admin/licenses/create';
import ListTrials from 'pages/admin/trials/list';

const ProtectedRoute = ({ user, isAllowed, redirectPath = '/', children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet context={user} />;
};

const AppRoutes = ({ user }) => {
  let isAuthorised = !isEmpty(user);
  let isCustomer = isAuthorised && user.roles.includes('customer');
  let isAdministrator = isAuthorised && user.roles.includes('administrator');

  return (
    <Routes>
      {/* Visitor Pages */}
      <Route element={<VisitorLayout />}>
        <Route exact path="/" element={<Landing />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* Trial Licensing Pages */}
      <Route element={<VisitorLayout />}>
        <Route exact path="/request/trial" element={<RequestTrialLicense />} />
      </Route>

      {/* Authentication Pages */}
      <Route element={<AuthenticationLayout />}>
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/logout" element={<LogoutRedirect />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
      </Route>

      {/* Administrator Dashboard Pages*/}
      <Route element={<AdministratorDashboardLayout />}>
        <Route
          exact
          path="/admin/dashboard"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <ListAdmins />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/create"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <CreateAdmins />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/users/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <ListUsers />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/users/create"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <CreateUsers />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/users/edit"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <EditUsers />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/users/activity/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <ListUsers />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/backups/activity/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <BackupActivity />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/restores/activity/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <RestoreActivity />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/analytics/view"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <ViewAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/licenses/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <ListLicenses />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/licenses/create"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <CreateLicenses />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/trials/list"
          element={
            <ProtectedRoute
              redirectPath="/login"
              isAllowed={isAuthorised && isAdministrator}
            >
              <ListTrials />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Customer Dashboard Pages*/}
      <Route element={<CustomerDashboardLayout user={user} />}>
        <Route
          element={
            <ProtectedRoute
              user={user}
              redirectPath="/login"
              isAllowed={isAuthorised && isCustomer}
            />
          }
        >
          <Route
            exact
            path="/my-license"
            user={user}
            element={<RegularMyLicense />}
          />
          <Route
            exact
            path="/my-account"
            user={user}
            element={<RegularMyAccount />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
