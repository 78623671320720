import { Typography, Button } from '@mui/material';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

const NotFound = () => {
  return (
    <Container component="main" maxWidth="xm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">
          We couldn't find what you're looking for...
        </Typography>
        <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: 80 }} />
        <Link to="/">
          <Button variant="contained">Go Home</Button>
        </Link>
      </Box>
    </Container>
  );
};

export default NotFound;
