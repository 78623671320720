import { useState } from 'react';
import * as React from 'react';
import Axios from 'axios';
import Config from 'config';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IvyBackupLogo from 'assets/images/logos/main-logo.png';
import toast from 'react-hot-toast';

const Register = () => {
  const [isRegistered, setRegistered] = useState(false);

  const RegisterUser = async (firstName, lastName, email, password) => {
    let response = {};

    try {
      response = await Axios.post(
        `${Config.SERVER}/api/v2/user/register`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      toast.error(error?.response?.data);
    }

    return response.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    let firstName = data.get('firstName');
    let lastName = data.get('lastName');
    let email = data.get('email');
    let password = data.get('password');

    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      password.length === 0
    ) {
      toast.error('Please fill out empty fields.');
      return;
    }

    let response = await RegisterUser(firstName, lastName, email, password);

    if (response) {
      setRegistered(true);
    }
  };

  return (
    <Box>
      {isRegistered ? (
        <Container component="main" maxWidth="xm">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Card
              raised
              sx={{
                p: '50px',
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar
                alt="IvyBackup Logo"
                src={IvyBackupLogo}
                sx={{ width: 100, height: 100 }}
              />
              <Typography component="h1" variant="h5" align="center">
                Account Verification
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ marginTop: '10px' }}
              >
                To verify your account, check your email for more details.
              </Typography>
              <Link to="/login">
                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Sign in
                </Button>
              </Link>
            </Card>
          </Box>
        </Container>
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt="IvyBackup Logo"
              src={IvyBackupLogo}
              sx={{ width: 100, height: 100 }}
            />
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontWeight: '700' }}
              >
                Register
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login">
                    <Typography variant="body1">
                      Already have an account?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.ivybackup.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant="body1">
                      We respect your privacy. Click to learn more
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default Register;
