import { useEffect, useState } from "react";

// material-ui
import { Grid } from "@mui/material";

// project imports
import { gridSpacing } from "store/constant";
import TrialsCard from "ui-component/cards/admin/trials-card";
import LicensesCard from "ui-component/cards/admin/licenses-card";
import UserActivityCard from "ui-component/cards/admin/user-activity-card";
import NewUsersCard from "ui-component/cards/admin/new-users-card";
import BackupActivityCard from "ui-component/cards/admin/backup-activity-card";
import RestoreActivityCard from "ui-component/cards/admin/restore-activity-card";

const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  let queryStartDate = new Date();
  queryStartDate.setHours(0, 0, 0, 0);

  let queryEndDate = new Date();
  queryEndDate.setHours(24, 59, 59, 0);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <TrialsCard
              cardTitle="Today's Trials"
              queryStartDate={queryStartDate}
              queryEndDate={queryEndDate}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <LicensesCard
              cardTitle="Today's Licenses"
              queryStartDate={queryStartDate}
              queryEndDate={queryEndDate}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <UserActivityCard
              cardTitle="Active Users Today"
              queryStartDate={queryStartDate}
              queryEndDate={queryEndDate}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <BackupActivityCard
              cardTitle="Total Backups Run Today"
              queryStartDate={queryStartDate}
              queryEndDate={queryEndDate}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <RestoreActivityCard
              cardTitle="Restores Run Today"
              queryStartDate={queryStartDate}
              queryEndDate={queryEndDate}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <NewUsersCard
              cardTitle="New Users Today"
              queryStartDate={queryStartDate}
              queryEndDate={queryEndDate}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
