import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Config from "config";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

// material-ui
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
} from "@mui/material";

// project imports
import SkeletonTrialsCard from "ui-component/cards/skeleton/admin/trials-card";

const TrialsCard = ({ isLoading, cardTitle, queryStartDate, queryEndDate }) => {
  const [trialsCount, setTrialsCount] = useState(0);

  const customization = useSelector((state) => state.customization);

  const user = customization.user;

  useEffect(() => {
    const getTrials = async () => {
      let response = {};

      try {
        let params = `pageNo=1&size=10&startDate=${queryStartDate}&endDate=${queryEndDate}`;
        response = await Axios.get(
          `${Config.SERVER}/api/v2/trial/from-range?${params}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": user.token,
            },
            timeout: 5000,
          }
        );
      } catch (error) {
        if (error?.response?.data) toast.error(error?.response?.data);
      }

      return response.data;
    };

    getTrials().then((trials) => {
      if (trials) {
        setTrialsCount(trials.count);
      }
    });
  }, [queryStartDate, queryEndDate, user?.token]);

  return (
    <>
      {isLoading ? (
        <SkeletonTrialsCard />
      ) : (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardHeader title={cardTitle} />
              <Link to="/admin/trials/list">
                <CardContent>
                  <Typography variant="h3" color="text.secondary">
                    {trialsCount}
                  </Typography>
                </CardContent>
              </Link>
            </Box>
          </CardActionArea>
        </Card>
      )}
    </>
  );
};

TrialsCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default TrialsCard;
