// assets
import {
  IconDashboard,
  IconCalendar,
  IconKey,
  IconUser,
  IconCheck,
  IconLogout,
  IconArrowBarUp,
  IconArrowBarToDown,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconCalendar,
  IconKey,
  IconUser,
  IconCheck,
  IconLogout,
  IconArrowBarUp,
  IconArrowBarToDown,
};

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/admin/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "trials",
      title: "Trials",
      type: "item",
      url: "/admin/trials/list",
      icon: icons.IconCalendar,
      breadcrumbs: false,
    },
    {
      id: "licenses",
      title: "Licenses",
      type: "item",
      url: "/admin/licenses/list",
      icon: icons.IconKey,
      breadcrumbs: false,
    },
    {
      id: "user-activity",
      title: "User Activity",
      type: "item",
      url: "/admin/users/activity/list",
      icon: icons.IconUser,
      breadcrumbs: false,
    },
    {
      id: "backup-activity",
      title: "Backup Activity",
      type: "item",
      url: "/admin/backups/activity/list",
      icon: icons.IconArrowBarToDown,
      breadcrumbs: false,
    },
    {
      id: "restore-activity",
      title: "Restore Activity",
      type: "item",
      url: "/admin/restores/activity/list",
      icon: icons.IconArrowBarUp,
      breadcrumbs: false,
    },
    {
      id: "logout",
      title: "Logout",
      type: "item",
      url: "/logout",
      icon: icons.IconLogout,
      breadcrumbs: false,
    },
  ],
};

const menuItems = {
  items: [dashboard],
};

export default menuItems;
