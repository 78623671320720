import * as React from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Config from 'config';
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import useLocalStorageState from 'use-local-storage-state';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import IvyBackupLogo from 'assets/images/logos/main-logo.png';

const Login = () => {
  let navigate = useNavigate();

  const [user, setUser] = useLocalStorageState('user', {
    ssr: false,
    defaultValue: {},
  });

  const LoginUser = async (email, password) => {
    let response = {};

    try {
      response = await Axios.post(
        `${Config.SERVER}/auth/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      toast.error(error?.response?.data);
    }

    return response.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    let email = data.get('email');
    let password = data.get('password');

    if (email.length === 0 || password.length === 0) {
      toast.error('Please fill out empty fields.');
      return;
    }

    let response = await LoginUser(email, password);

    if (response) {
      let user = {
        token: response.token,
        expires: response.expires,

        active: response.user.active,
        date: response.user.date,
        email: response.user.email,
        firstName: response.user.firstName,
        lastName: response.user.lastName,
        type: response.user.type,
        registered: response.user.registered,
        verified: response.user.verified,
        roles: response.user.roles,
        _id: response.user._id,
      };

      setUser(user);

      if (user.type === 'customer') navigate('/my-license');
      else if (user.type === 'administrator') navigate('/admin/dashboard');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          alt="IvyBackup Logo"
          src={IvyBackupLogo}
          sx={{ width: 100, height: 100 }}
        />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, fontWeight: '700' }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item>
              <Link to="/register">
                <Typography variant="body1">Don't have an account?</Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
