import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import * as React from 'react';
import Axios from 'axios';
import Config from 'config';
import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const TopPaddedBox = ({ children }) => {
  return <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>{children}</Box>;
};

const MyAccount = () => {
  const [user, setUser] = useLocalStorageState('user', {
    ssr: false,
    defaultValue: {},
  });

  const [license, setLicense] = useState({});
  const [licensedTo, setLicenseName] = useState('');
  const [licenseKey, setLicenseKey] = useState('');

  const getLicenseInfo = async (user) => {
    let response = {};

    try {
      response = await Axios.get(
        `${Config.SERVER}/api/v2/licensing/email/${user.email}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': user.token,
          },
        }
      );
    } catch (error) {
      //toast.error(error?.response?.data);
    }

    return response.data;
  };

  useEffect(() => {
    getLicenseInfo(user).then((license) => {
      setLicense(license);
      setLicenseName(license.licensedTo);
      setLicenseKey(license.licenseKey);
    });
  }, [user]);

  return (
    <Box>
      <Typography variant="h5">License Settings</Typography>
      <Divider />

      {!user?.registered ? (
        <Container sx={{ marginTop: '20px' }}>
          <TopPaddedBox>
            <Card sx={{ padding: '20px' }}>
              <Box>
                <Typography variant="h6">
                  You currently don't have a license.
                </Typography>
                <Typography variant="body2">
                  Your profile is not associated with a valid IvyBackup license.
                  Please purchase a new license via the our online store to
                  continue using the software.
                </Typography>

                <TopPaddedBox>
                  <Typography variant="h6">Have a legacy license?.</Typography>
                  <Typography variant="body2">
                    If you currently have a valid IvyBackup license, it is very
                    likely to be a 'Legacy License' and not yet associated with
                    our new License Manager. Please contact sales below to find
                    out your options for migrating your Legacy License to the
                    License Manager.
                  </Typography>
                </TopPaddedBox>

                <Divider sx={{ marginTop: '20px' }} />
                <Box display="flex" alignItems="center" justifyContent="center">
                  <a
                    href="https://www.ivybackup.com/pricing/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant="contained" sx={{ marginTop: '10px' }}>
                      Buy A License
                    </Button>
                  </a>

                  <a
                    href="https://www.ivybackup.com/contact-us/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant="contained"
                      sx={{ marginLeft: '10px', marginTop: '10px' }}
                    >
                      Contact Sales
                    </Button>
                  </a>
                </Box>
              </Box>
            </Card>
          </TopPaddedBox>
        </Container>
      ) : (
        <Container sx={{ marginTop: '20px' }}>
          <TopPaddedBox>
            <Card sx={{ padding: '20px' }}>
              <Box>
                <Typography variant="h6">
                  Welcome to your IvyBackup License
                </Typography>

                <Box sx={{ marginTop: '30px', fontWeight: '700' }}>
                  <Typography variant="h7">Licensed to:</Typography>
                </Box>

                <Box sx={{ marginTop: '5px' }}>
                  <Typography variant="h7">{licensedTo}</Typography>
                </Box>

                <Box sx={{ marginTop: '10px', fontWeight: '700' }}>
                  <Typography variant="h7">License key:</Typography>
                </Box>

                <Box sx={{ marginTop: '5px' }}>
                  <Typography variant="h7">{licenseKey}</Typography>
                </Box>

                <Divider sx={{ marginTop: '20px' }} />

                <Box sx={{ marginTop: '5px' }}>
                  <Typography variant="h7">
                    To use your license with IvyBackup, ensure you have
                    downloaded the latest version available{' '}
                    <a href="https://www.ivybackup.com/download-ivybackup/">
                      {' '}
                      here
                    </a>
                    .
                  </Typography>
                </Box>

                <Box sx={{ marginTop: '5px' }}>
                  <Typography variant="h7">
                    1. Open IvyBackup. Go to the Home Menu, and click on
                    "Register Product".
                  </Typography>
                </Box>
              </Box>
            </Card>
          </TopPaddedBox>
        </Container>
      )}
    </Box>
  );
};

export default MyAccount;
