import { useEffect, useState } from "react";
import Axios from "axios";
import Config from "config";
import {
  Button,
  Card,
  Avatar,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useSearchParams, Link } from "react-router-dom";
import IvyBackupLogo from "assets/images/logos/main-logo.png";
import toast from "react-hot-toast";

const VerifyEmail = () => {
  const [accountVerified, setAccountVerified] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  let emailToken = searchParams.get("token");

  const setUserVerified = async (token) => {
    let response = {};

    try {
      response = await Axios.post(
        `${Config.SERVER}/auth/verify-account`,
        {
          emailToken: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      toast.error(error?.response?.data);
    }

    return response.data;
  };

  useEffect(() => {
    setUserVerified(emailToken).then((response) => {
      if (response) {
        setAccountVerified(true);
      }
    });
  }, [emailToken]);

  return (
    <Container component="main" maxWidth="xm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          raised
          sx={{
            p: "50px",
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            alt="IvyBackup Logo"
            src={IvyBackupLogo}
            sx={{ width: 100, height: 100 }}
          />
          <Typography component="h1" variant="h5" align="center">
            Account Verification
          </Typography>

          {accountVerified ? (
            <Box>
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ marginTop: "10px" }}
              >
                Your account has been verified successfully!
              </Typography>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckIcon sx={{ fontSize: 80 }} />
              </Box>
              <Link to="/login">
                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Continue
                </Button>
              </Link>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ marginTop: "10px" }}
              >
                Please wait a moment as we verify your account.
              </Typography>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="success" />
              </Box>
            </Box>
          )}
        </Card>
      </Box>
    </Container>
  );
};

export default VerifyEmail;
