import * as React from 'react';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import IvyBackupLogo from 'assets/images/logos/main-logo.png';

const Landing = () => {
  return (
    <Container component="main" maxWidth="xm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card
          raised
          sx={{
            p: '50px',
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            alt="IvyBackup Logo"
            src={IvyBackupLogo}
            sx={{ width: 100, height: 100 }}
          />
          <Typography component="h1" variant="h5" align="center">
            Welcome to the IvyBackup License Manager
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ marginTop: '10px' }}
          >
            To manage and update your license, please sign in or create an account:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ mt: 3, mb: 2, fontWeight: '700' }}>
                Sign in
              </Button>
            </Link>
            <Link to="/register" style={{ textDecoration: 'none' }}>
              <Button variant="contained" sx={{ ml:3, mt: 3, mb: 2, fontWeight: '700' }}>
                Register
              </Button>
            </Link>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default Landing;
