import { useState, useEffect } from "react";
import Axios from "axios";
import Config from "config";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import truncateString from "services/truncate-string.js";
import { Box, CircularProgress } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { visuallyHidden } from "@mui/utils";
import toast from "react-hot-toast";

///
/// Table Pagination Actions
///
const CustomPagination = (props) => {
  const { page, totalPages, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    let newPage = page - 1;
    if (newPage < 1) {
      newPage = 1;
    }
    onPageChange(event, newPage);
  };

  const handleNextButtonClick = (event) => {
    let newPage = page + 1;
    if (newPage > totalPages) {
      newPage = totalPages;
    }
    onPageChange(event, newPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= totalPages}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

///
/// Enhanced Table Head
///

const headCells = [
  {
    id: "id",
    disablePadding: true,
    label: "License ID",
  },
  {
    id: "machine-id",
    disablePadding: false,
    label: "Machine ID",
  },
  {
    id: "licensed-to",
    disablePadding: false,
    label: "Licensed To",
  },
  {
    id: "license-key",
    disablePadding: false,
    label: "License Key",
  },
  {
    id: "date-purchased",
    disablePadding: false,
    label: "Date Purchased",
  },
  {
    id: "blacklisted",
    disablePadding: false,
    label: "Blacklisted",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

///
/// Enhanced Table Toolbar
///

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Licenses
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

///
/// Paginated Table
///

const PaginatedLicensesTable = () => {
  const customization = useSelector((state) => state.customization);
  const user = customization.user;

  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);

  const [licenses, setLicenses] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const getLicenses = async () => {
      let response = {};

      try {
        let params = `pageNo=${page}&size=${itemsPerPage}`;
        response = await Axios.get(
          `${Config.SERVER}/api/v2/licensing?${params}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": user.token,
            },
            timeout: 5000,
          }
        );
      } catch (error) {
        if (error?.response?.data) toast.error(error?.response?.data);
      }

      return response.data;
    };

    getLicenses().then((licenses) => {
      setIsLoading(false);

      if (licenses) {
        setLicenses(licenses.message);
        setTotalPages(licenses.pages);
        setTotalRecords(licenses.count);
      }
    });
  }, [page, itemsPerPage, user?.token]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = licenses.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, itemsPerPage - licenses.length);

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <CircularProgress size="80px" color="inherit" />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{
                  minWidth: 750,
                  //   "& ..MuiTableHead-root .MuiTableRow-root .MuiTableCell-head":
                  //     {
                  //       fontWeight: "bold",
                  //       resize: "horizontal",
                  //       overflowX: "overlay",
                  //       overflowY: "hidden",
                  //       borderRight: "1px solid",
                  //     },
                }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={licenses.length}
                />
                <TableBody>
                  {licenses.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {truncateString(row._id)}
                        </TableCell>
                        <TableCell align="left">
                          {truncateString(row.machineIdentifier)}
                        </TableCell>
                        <TableCell align="left">{row.licensedTo}</TableCell>
                        <TableCell align="left">{row.licenseKey}</TableCell>
                        <TableCell alig="left">
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }).format(new Date(row.datePurchased))}
                        </TableCell>
                        <TableCell align="left">
                          {row.blacklisted ? "true" : "false"}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "10px",
                }}
              >
                {licenses.length <= 0 ? (
                  <Typography>No Records</Typography>
                ) : (
                  <Typography>
                    Showing {page * itemsPerPage - itemsPerPage + 1} -{" "}
                    {page * itemsPerPage - itemsPerPage + licenses.length} of{" "}
                    {totalRecords} Records
                  </Typography>
                )}
                <CustomPagination
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handleChangePage}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default PaginatedLicensesTable;
