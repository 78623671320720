import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/transitions';

// assets
import {
  IconDashboard,
  IconCalendar,
  IconKey,
  IconUser,
  IconCheck,
  IconLogout,
  IconSettings,
} from '@tabler/icons';

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: '10px' }}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 250,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '10px',
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%',
                      },
                      '& .MuiListItemButton-root': {
                        mt: 0.5,
                      },
                    }}
                  >
                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 0}
                      onClick={(event) =>
                        handleListItemClick(event, 0, '/admin/dashboard')
                      }
                    >
                      <ListItemIcon>
                        <IconDashboard stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Dashboard</Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 1}
                      onClick={(event) =>
                        handleListItemClick(event, 0, '/admin/trials/list')
                      }
                    >
                      <ListItemIcon>
                        <IconCalendar stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Trials</Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 1}
                      onClick={(event) =>
                        handleListItemClick(event, 0, '/admin/licenses/list')
                      }
                    >
                      <ListItemIcon>
                        <IconKey stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Licenses</Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 1}
                      onClick={(event) =>
                        handleListItemClick(
                          event,
                          0,
                          '/admin/users/activity/list'
                        )
                      }
                    >
                      <ListItemIcon>
                        <IconUser stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">User Activity</Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 1}
                      onClick={(event) =>
                        handleListItemClick(
                          event,
                          0,
                          '/admin/backups/activity/list'
                        )
                      }
                    >
                      <ListItemIcon>
                        <IconCheck stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            Backup Activity
                          </Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 2}
                      onClick={(event) =>
                        handleListItemClick(event, 0, '/logout')
                      }
                    >
                      <ListItemIcon>
                        <IconLogout stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Logout</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
