import { Link } from 'react-router-dom';

// material-ui
import { Avatar, ButtonBase } from '@mui/material';
import IvyBackupLogo from 'assets/images/logos/main-logo.png';

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to="/">
    <Avatar
      alt="IvyBackup Logo"
      src={IvyBackupLogo}
      sx={{ width: 50, height: 50 }}
    />
  </ButtonBase>
);

export default LogoSection;
