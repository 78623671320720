import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import Axios from "axios";
import Config from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useLocalStorageState from "use-local-storage-state";
import toast from "react-hot-toast";

const TopPaddedBox = ({ children }) => {
  return <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>{children}</Box>;
};

const MyAccount = () => {
  const [localUser, setLocalUser] = useLocalStorageState("user", {
    ssr: false,
    defaultValue: {},
  });

  const customization = useSelector((state) => state.customization);

  const user = customization.user;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setFirstName(user?.firstName);
  }, [user]);
  useEffect(() => {
    setLastName(user?.lastName);
  }, [user]);
  useEffect(() => {
    setEmailAddress(user?.email);
  }, [user]);

  const UpdateUser = async (new_user) => {
    let response = {};

    try {
      response = await Axios.post(
        `${Config.SERVER}/api/v2/user/update/${localUser._id}`,
        {
          firstName: new_user.firstName,
          lastName: new_user.lastName,
          email: new_user.email,
          password: new_user.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localUser.token,
          },
        }
      );
    } catch (error) {
      toast.error(error?.response?.data);
    }

    return response.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    let new_user = {
      firstName: data.get("first-name"),
      lastName: data.get("last-name"),
      email: data.get("email"),
      password: data.get("password"),
    };

    let response = await UpdateUser(new_user);

    if (response) {
      let user = {
        _id: localUser._id,
        token: localUser.token,
        expires: localUser.expires,
        active: response.active,
        date: response.date,
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        type: response.type,
        verified: response.verified,
        roles: response.roles,
      };

      setLocalUser(user);

      // TODO: Add a success toast notification here
    } else {
      // TODO: Add an error toast notification here
    }
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h5">Account Settings</Typography>
      <Divider />
      <Container sx={{ marginTop: "20px" }}>
        <TopPaddedBox>
          <Card sx={{ padding: "20px", maxWidth: "600px" }}>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Typography>First Name</Typography>
              <TopPaddedBox>
                <TextField
                  fullWidth
                  value={firstName}
                  onChange={handleFirstNameChange}
                  id="first-name"
                  name="first-name"
                  label="First Name"
                  variant="outlined"
                />
              </TopPaddedBox>
              <Typography>Last Name</Typography>
              <TopPaddedBox>
                <TextField
                  fullWidth
                  value={lastName}
                  onChange={handleLastNameChange}
                  id="last-name"
                  name="last-name"
                  label="Last Name"
                  variant="outlined"
                />
              </TopPaddedBox>
              <Typography>Email Address</Typography>
              <TopPaddedBox>
                <TextField
                  fullWidth
                  disabled
                  value={emailAddress}
                  onChange={handleEmailChange}
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                />
              </TopPaddedBox>
              <Typography>Password</Typography>
              <TopPaddedBox>
                <TextField
                  fullWidth
                  value={password}
                  onChange={handlePasswordChange}
                  type="password"
                  id="password"
                  name="password"
                  label="Password"
                  variant="outlined"
                />
              </TopPaddedBox>

              <Divider sx={{ marginTop: "20px" }} />
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ marginTop: "10px" }}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </Card>
        </TopPaddedBox>
      </Container>
    </Box>
  );
};

export default MyAccount;
