import { useNavigate } from "react-router";
import { Container, Box, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const LogoutRedirect = () => {
  localStorage.removeItem("user");

  let navigate = useNavigate();

  setTimeout(() => navigate("/"), 3000);

  return (
    <Container component="main" maxWidth="xm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          Logged out. You will be redirected automatically.
        </Typography>
        <LogoutIcon sx={{ fontSize: 80 }} />
      </Box>
    </Container>
  );
};

export default LogoutRedirect;
